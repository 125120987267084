import { createRouter, createWebHistory } from 'vue-router'
import DirectusDataService from '@/services/DirectusDataService'
import { Item } from "@directus/sdk";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/layouts/SiteLayout.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/HomeView.vue'),
        }
      ]
    },
    {
      path: '/consent',
      name: 'consent',
      component: () => import('@/layouts/ConsentLayout.vue'),
      children: [
        {
          path: '/consent/:uuid',
          component: () => import('@/views/ConsentView.vue'),
        }
      ]
    },
    {
      path: '/careers',
      name: 'careers',
      component: () => import('@/layouts/SiteLayout.vue'),
      children: [
        {
          path: '/careers',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/:slug',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/filter/:locationID',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/locations/:locationName',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/:slug/:source',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/filter/:locationID/:source',
          component: () => import('@/views/CareersView.vue'),
        },
        {
          path: '/careers/locations/:locationName/:source',
          component: () => import('@/views/CareersView.vue'),
        },
      ]
    },
    {
      path: '/internal',
      name: 'internal',
      component: () => import('@/layouts/SiteLayout.vue'),
      children: [
        {
          path: '/internal/:slug',
          component: () => import('@/views/InternalView.vue'),
        },
        {
          path: '/internal',
          component: () => import('@/views/InternalView.vue'),
        },
      ]
    },
    {
      path: '/old',
      name: 'old',
      component: () => import('@/layouts/SiteLayout.vue'),
      children: [
        {
          path: '/old',
          component: () => import('@/views/OldView.vue'),
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: '/login',
          component: () => import('@/views/LoginView.vue'),
        },
        {
          path: '/login/:slug',
          component: () => import('@/views/LoginView.vue'),
        },
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: '/logout',
          component: () => import('@/views/LogoutView.vue'),
        },
        {
          path: '/logout/:slug',
          component: () => import('@/views/LogoutView.vue'),
        },
      ]
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: '/activate/:activation_code',
          component: () => import('@/views/ActivationView.vue'),
        },
      ]
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: '/register',
          component: () => import('@/views/RegisterView.vue'),
        },
        {
          path: '/register/:slug',
          component: () => import('@/views/RegisterView.vue'),
        }
      ]
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: '/registration',
          component: () => import('@/views/public/registration/RegisterView.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/registers/preview/:data',
          component: () => import('@/views/secured/templates/registers/RegisterPreview.vue'),
        },
      ]
    },
    {
      path: '/dashboard',
      name: 'View dashboard',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View dashboard', },
          path: '/dashboard',
          component: () => import('@/views/secured/dashboard/SystemDashboard.vue'),
        },
        {
          meta: { name: 'View dashboard', },
          path: '/dashboard/candidate',
          component: () => import('@/views/secured/dashboard/candidate/SystemDashboard.vue'),
        },
      ]
    },
    {
      path: '/applications',
      name: 'View applications',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View applications', },
          path: '/applications/candidate',
          component: () => import('@/views/secured/applications/candidate/JobApplications.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/applications',
          component: () => import('@/views/secured/applications/SystemApplications.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/applications/:applicationID/:jobID',
          component: () => import('@/views/secured/applications/SystemApplications.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/applications/:applicationID/:jobID/:actionType',
          component: () => import('@/views/secured/applications/SystemApplications.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/applications/inactive',
          component: () => import('@/views/secured/applications/SystemApplicationsInactive.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/reports/vacancy',
          component: () => import('@/views/secured/reports/VacancyOverview.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/reports/stage',
          component: () => import('@/views/secured/reports/StageOverview.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/reports',
          component: () => import('@/views/secured/reports/SystemReports.vue'),
        },
        {
          meta: { name: 'View applications', },
          path: '/lookup',
          component: () => import('@/views/secured/applications/LookUp.vue'),
        },
      ]
    },
    {
      path: '/profiles',
      name: 'View profiles',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View applications', },
          path: '/profiles/candidate',
          component: () => import('@/views/secured/profiles/candidate/CandidateProfile.vue'),
        },
      ]
    },
    {
      path: '/documents',
      name: 'View documents',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View documents', },
          path: '/documents/candidate',
          component: () => import('@/views/secured/documents/candidate/CandidateDocuments.vue'),
        },
      ]
    },
    {
      path: '/users',
      name: 'User Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View users', },
          path: '/users',
          component: () => import('@/views/secured/users/SystemUsers.vue'),
        },
        {
          meta: { name: 'Create users', },
          path: '/users/create',
          component: () => import('@/views/secured/users/SystemUsersCreate.vue'),
        },
        {
          meta: { name: 'Update users', },
          path: '/users/edit/:id',
          component: () => import('@/views/secured/users/SystemUsersEdit.vue'),
        },
        {
          meta: { name: 'View users', },
          path: '/users/inactive',
          component: () => import('@/views/secured/users/SystemUsersInactive.vue'),
        },
        {
          meta: { name: 'View roles', },
          path: '/roles',
          component: () => import('@/views/secured/users/UserRoles.vue'),
        },
        {
          meta: { name: 'Create roles', },
          path: '/roles/create',
          component: () => import('@/views/secured/users/UserRolesCreate.vue'),
        },
        {
          meta: { name: 'Update roles', },
          path: '/roles/edit/:id',
          component: () => import('@/views/secured/users/UserRolesEdit.vue'),
        },
        {
          meta: { name: 'View roles', },
          path: '/roles/inactive',
          component: () => import('@/views/secured/users/UserRolesInactive.vue'),
        },
      ]
    },
    {
      path: '/territories',
      name: 'Role Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View locations', },
          path: '/territories',
          component: () => import('@/views/secured/territories/SystemTerritories.vue'),
        },
        {
          meta: { name: 'Create locations', },
          path: '/territories/create',
          component: () => import('@/views/secured/territories/SystemTerritoriesCreate.vue'),
        },
        {
          meta: { name: 'Update locations', },
          path: '/territories/edit/:id',
          component: () => import('@/views/secured/territories/SystemTerritoriesEdit.vue'),
        },
        {
          meta: { name: 'Update locations', },
          path: '/territories/defaults/:id',
          component: () => import('@/views/secured/territories/SystemTerritoriesDefaults.vue'),
        },
        {
          meta: { name: 'View locations', },
          path: '/territories/inactive',
          component: () => import('@/views/secured/territories/SystemTerritoriesInactive.vue'),
        },
      ]
    },
    {
      path: '/fields',
      name: 'Field Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View fields', },
          path: '/fields',
          component: () => import('@/views/secured/fields/SystemFields.vue'),
        },
        {
          meta: { name: 'Create fields', },
          path: '/fields/create',
          component: () => import('@/views/secured/fields/SystemFieldsCreate.vue'),
        },
        {
          meta: { name: 'Update fields', },
          path: '/fields/edit/:id',
          component: () => import('@/views/secured/fields/SystemFieldsEdit.vue'),
        },
        {
          meta: { name: 'View fields', },
          path: '/fields/inactive',
          component: () => import('@/views/secured/fields/SystemFieldsInactive.vue'),
        },
      ]
    },
    {
      path: '/tags',
      name: 'Tags Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View tags', },
          path: '/tags',
          component: () => import('@/views/secured/tags/SystemTags.vue'),
        },
        {
          meta: { name: 'Create tags', },
          path: '/tags/create',
          component: () => import('@/views/secured/tags/SystemTagsCreate.vue'),
        },
        {
          meta: { name: 'Update tags', },
          path: '/tags/edit/:id',
          component: () => import('@/views/secured/tags/SystemTagsEdit.vue'),
        },
        {
          meta: { name: 'View tags', },
          path: '/tags/inactive',
          component: () => import('@/views/secured/tags/SystemTagsInactive.vue'),
        },
      ]
    },
    {
      path: '/statuses',
      name: 'Status Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View statuses', },
          path: '/statuses',
          component: () => import('@/views/secured/statuses/SystemStatuses.vue'),
        },
        {
          meta: { name: 'Create statuses', },
          path: '/statuses/create',
          component: () => import('@/views/secured/statuses/SystemStatusesCreate.vue'),
        },
        {
          meta: { name: 'Update statuses', },
          path: '/statuses/edit/:id',
          component: () => import('@/views/secured/statuses/SystemStatusesEdit.vue'),
        },
        {
          meta: { name: 'View statuses', },
          path: '/statuses/inactive',
          component: () => import('@/views/secured/statuses/SystemStatusesInactive.vue'),
        },
      ]
    },
    {
      path: '/buttons',
      name: 'buttons',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View buttons', },
          path: '/buttons',
          component: () => import('@/views/secured/buttons/SystemButtons.vue'),
        },
        {
          meta: { name: 'Create button', },
          path: '/buttons/create',
          component: () => import('@/views/secured/buttons/SystemButtonsCreate.vue'),
        },
        {
          meta: { name: 'Update buttons', },
          path: '/buttons/edit/:id',
          component: () => import('@/views/secured/buttons/SystemButtonsEdit.vue'),
        },
        {
          meta: { name: 'View buttons', },
          path: '/buttons/inactive',
          component: () => import('@/views/secured/buttons/SystemButtonsInactive.vue'),
        },
      ]
    },
    {
      path: '/stages',
      name: 'Stage Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View stages', },
          path: '/stages',
          component: () => import('@/views/secured/stages/SystemStages.vue'),
        },
        {
          meta: { name: 'Create stages', },
          path: '/stages/create',
          component: () => import('@/views/secured/stages/SystemStagesCreate.vue'),
        },
        {
          meta: { name: 'Update stages', },
          path: '/stages/edit/:id',
          component: () => import('@/views/secured/stages/SystemStagesEdit.vue'),
        },
        {
          name: 'View stages',
          path: '/stages/inactive',
          component: () => import('@/views/secured/stages/SystemStagesInactive.vue'),
        },
      ]
    },
    {
      path: '/templates',
      name: 'Templates Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View templates', },
          path: '/templates/surveys',
          component: () => import('@/views/secured/templates/interviews/InterviewSurveys.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/surveys/create',
          component: () => import('@/views/secured/templates/interviews/InterviewSurveysCreate.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/surveys/edit/:id',
          component: () => import('@/views/secured/templates/interviews/InterviewSurveysEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/surveys/inactive',
          component: () => import('@/views/secured/templates/interviews/InterviewSurveysInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/questionnaires',
          component: () => import('@/views/secured/templates/questionnaires/InterviewQuestionnaires.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/questionnaires/inactive',
          component: () => import('@/views/secured/templates/questionnaires/InterviewQuestionnairesInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/questionnaires/create',
          component: () => import('@/views/secured/templates/questionnaires/InterviewQuestionnairesCreate.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/questionnaires/edit/:id',
          component: () => import('@/views/secured/templates/questionnaires/InterviewQuestionnairesEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/emails',
          component: () => import('@/views/secured/templates/emails/SystemEmails.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/emails/create',
          component: () => import('@/views/secured/templates/emails/SystemEmailsCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/emails/edit/:id',
          component: () => import('@/views/secured/templates/emails/SystemEmailsEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/emails/inactive',
          component: () => import('@/views/secured/templates/emails/SystemEmailsInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/offers',
          component: () => import('@/views/secured/templates/offers/SystemOffers.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/offers/create',
          component: () => import('@/views/secured/templates/offers/SystemOffersCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/offers/edit/:id',
          component: () => import('@/views/secured/templates/offers/SystemOffersEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/offers/inactive',
          component: () => import('@/views/secured/templates/offers/SystemOffersInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/consents',
          component: () => import('@/views/secured/templates/consents/SystemConsents.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/consents/create',
          component: () => import('@/views/secured/templates/consents/SystemConsentsCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/consents/edit/:id',
          component: () => import('@/views/secured/templates/consents/SystemConsentsEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/consents/inactive',
          component: () => import('@/views/secured/templates/consents/SystemConsentsInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/updates',
          component: () => import('@/views/secured/templates/updates/SystemUpdateRequests.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/updates/create',
          component: () => import('@/views/secured/templates/updates/SystemUpdateRequestsCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/updates/edit/:id',
          component: () => import('@/views/secured/templates/updates/SystemUpdateRequestsEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/updates/inactive',
          component: () => import('@/views/secured/templates/updates/SystemUpdateRequestsInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates/forms',
          component: () => import('@/views/secured/templates/candidates/forms/SystemCandidates.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/candidates/forms/create',
          component: () => import('@/views/secured/templates/candidates/forms/SystemCandidatesCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/candidates/forms/edit/:id',
          component: () => import('@/views/secured/templates/candidates/forms/SystemCandidatesEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates/forms/inactive',
          component: () => import('@/views/secured/templates/candidates/forms/SystemCandidatesInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates/forms/preview/:data',
          component: () => import('@/views/secured/templates/candidates/forms/CandidatePreview.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates',
          component: () => import('@/views/secured/templates/candidates/SystemCandidates.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/candidates/create',
          component: () => import('@/views/secured/templates/candidates/SystemCandidatesCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/candidates/edit/:id',
          component: () => import('@/views/secured/templates/candidates/SystemCandidatesEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates/inactive',
          component: () => import('@/views/secured/templates/candidates/SystemCandidatesInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/candidates/preview/:data',
          component: () => import('@/views/secured/templates/candidates/CandidatePreview.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/jobs',
          component: () => import('@/views/secured/templates/jobs/SystemJobs.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/jobs/create',
          component: () => import('@/views/secured/templates/jobs/SystemJobsCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/jobs/edit/:id',
          component: () => import('@/views/secured/templates/jobs/SystemJobsEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/jobs/preview/:data',
          component: () => import('@/views/secured/templates/jobs/JobPreview.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/jobs/preview/:data',
          component: () => import('@/views/secured/templates/jobs/JobPreview.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/jobs/workflow/:id',
          component: () => import('@/views/secured/templates/jobs/SystemJobsPipeline.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/jobs/inactive',
          component: () => import('@/views/secured/templates/jobs/SystemJobsInactive.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/forms/create',
          component: () => import('@/views/secured/templates/forms/SystemFormsCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/forms/edit/:id',
          component: () => import('@/views/secured/templates/forms/SystemFormsEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/forms',
          component: () => import('@/views/secured/templates/forms/SystemForms.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/forms/inactive',
          component: () => import('@/views/secured/templates/forms/SystemFormsInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/forms/preview/:data',
          component: () => import('@/views/secured/templates/forms/FormPreview.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/registers/create',
          component: () => import('@/views/secured/templates/registers/SystemRegistersCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/registers/edit/:id',
          component: () => import('@/views/secured/templates/registers/SystemRegistersEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/registers',
          component: () => import('@/views/secured/templates/registers/SystemRegisters.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/registers/inactive',
          component: () => import('@/views/secured/templates/registers/SystemRegistersInactive.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/profiles',
          component: () => import('@/views/secured/templates/profiles/SystemProfiles.vue'),
        },
        {
          meta: { name: 'Create templates', },
          path: '/templates/profiles/create',
          component: () => import('@/views/secured/templates/profiles/SystemProfilesCreate.vue'),
        },
        {
          meta: { name: 'Update templates', },
          path: '/templates/profiles/edit/:id',
          component: () => import('@/views/secured/templates/profiles/SystemProfilesEdit.vue'),
        },
        {
          meta: { name: 'View templates', },
          path: '/templates/profiles/inactive',
          component: () => import('@/views/secured/templates/profiles/SystemProfilesInactive.vue'),
        },
      ]
    },
    {
      path: '/jobs',
      name: 'Jobs Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'Create jobs', },
          path: '/jobs/create',
          component: () => import('@/views/secured/jobs/JobPostsCreate.vue'),
        },
        {
          meta: { name: 'Update jobs', },
          path: '/jobs/edit/:id',
          component: () => import('@/views/secured/jobs/JobPostsEdit.vue'),
        },
        {
          meta: { name: 'Update jobs', },
          path: '/jobs/workflow/:id',
          component: () => import('@/views/secured/jobs/JobPostsStagePipelinesCreateV3.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs',
          component: () => import('@/views/secured/jobs/JobPosts.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/inactive',
          component: () => import('@/views/secured/jobs/JobPostsInactive.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/draft',
          component: () => import('@/views/secured/jobs/JobPostsDraft.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/candidate',
          component: () => import('@/views/secured/jobs/candidate/JobPosts.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/candidate/:id',
          component: () => import('@/views/secured/jobs/candidate/JobPosts.vue'),
        },
      ]
    },
    {
      path: '/candidates',
      name: 'Candidates',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        {
          meta: { name: 'View candidates', },
          path: '/candidates',
          component: () => import('@/views/secured/candidates/SystemCandidates.vue'),
        },
        {
          meta: { name: 'View candidates', },
          path: '/candidates/unassigned',
          component: () => import('@/views/secured/candidates/SystemCandidatesUnassigned.vue'),
        },
        {
          meta: { name: 'View candidates', },
          path: '/candidates/view/:id',
          component: () => import('@/views/secured/candidates/SystemCandidatesView.vue'),
        },
        {
          meta: { name: 'View candidates', },
          path: '/candidates/applications/:id',
          component: () => import('@/views/secured/candidates/SystemCandidatesApplications.vue'),
        },
        {
          meta: { name: 'View candidates', },
          path: '/candidates/importer',
          component: () => import('@/views/secured/candidates/SystemCandidatesImporter.vue'),
        },
      ]
    },
    {
      path: '/tickets',
      name: 'Tickets Management',
      meta: { requiresAuth: true },
      component: () => import('@/layouts/SystemLayout.vue'),
      children: [
        /*{
          meta: { name: 'Create requestsa', },
          path: '/tickets/create',
          component: () => import('@/views/secured/jobs/JobPostsCreate.vue'),
        },
        {
          meta: { name: 'Update jobs', },
          path: '/tickets/edit/:id',
          component: () => import('@/views/secured/jobs/JobPostsEdit.vue'),
        },
        {
          meta: { name: 'Update jobs', },
          path: '/jobs/workflow/:id',
          component: () => import('@/views/secured/jobs/JobPostsStagePipelinesCreateV3.vue'),
        },*/
        {
          meta: { name: 'View tickets', },
          path: '/tickets/pending',
          component: () => import('@/views/secured/tickets/TicketsPending.vue'),
        },
        {
          meta: { name: 'View tickets', },
          path: '/tickets/active',
          component: () => import('@/views/secured/tickets/TicketsActive.vue'),
        },
        {
          meta: { name: 'View tickets', },
          path: '/tickets/onhold',
          component: () => import('@/views/secured/tickets/TicketsOnHold.vue'),
        },
        {
          meta: { name: 'View tickets', },
          path: '/tickets/closed',
          component: () => import('@/views/secured/tickets/TicketsClosed.vue'),
        },
        {
          meta: { name: 'View tickets', },
          path: '/tickets/concluded',
          component: () => import('@/views/secured/tickets/TicketsConcluded.vue'),
        },
        {
          meta: { name: 'Create tickets', },
          path: '/tickets/create',
          component: () => import('@/views/secured/tickets/TicketsCreate.vue'),
        },
        {
          meta: { name: 'View tickets', },
          path: '/tickets',
          component: () => import('@/views/secured/tickets/Tickets.vue'),
        },
        /*{
          meta: { name: 'View jobs', },
          path: '/jobs/inactive',
          component: () => import('@/views/secured/jobs/JobPostsInactive.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/draft',
          component: () => import('@/views/secured/jobs/JobPostsDraft.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/candidate',
          component: () => import('@/views/secured/jobs/candidate/JobPosts.vue'),
        },
        {
          meta: { name: 'View jobs', },
          path: '/jobs/candidate/:id',
          component: () => import('@/views/secured/jobs/candidate/JobPosts.vue'),
        },*/
      ]
    },
    {
      path: '/access-denied',
      name: 'AccessDenied',
      component: () => import('@/views/AccessDenied.vue'),
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    const isAuthorized = await checkAuth(to); // Implement your authentication and access check
    if (isAuthorized === 'Invalid') {
      // Redirect to the "Access Denied" page if not authenticated
      next({ name: 'AccessDenied' });
    } 
    else if (isAuthorized === 'Not logged-in') {
      next({ name: 'login' });
    }
    else {
      next(); // Proceed to the protected route
    }
  } else {
    next(); // For routes that do not require authentication
  }
});

async function checkAuth(route:Item) {
  const directusService = new DirectusDataService(process.env.VUE_APP_DIRECTUS_API_URL);
  const is_verified = await directusService.verifyToken();
  
  if(is_verified===true){
    const access_list = await directusService.returnMe();
    if(access_list.full_data.role.id==='cdeb2ade-a307-4f93-9cac-12a47a79b13e') { //System User
      if(access_list.user_role.access.includes(route.meta.name)){
        return 'Valid';
      } else {
        return 'Invalid'
      }
    } else if(access_list.full_data.role.id==='377da95c-6518-4715-b32d-ac9825cb42bd') { //Candidate
      const candidate_access=['View applications','View dashboard','View account','View jobs','View profiles','View documents'];
      if(candidate_access.includes(route.meta.name)){
        return 'Valid';
      } else {
        return 'Invalid'
      }
    } else if(access_list.full_data.role.id==='b6c1cba1-21b6-485d-a2ec-e334c6adac30') { // Admin
      return 'Valid';
    }
  } else { 
    return 'Not logged-in'; 
  }
}

export default router
